import queryString from 'query-string';

import { v1 } from './common/clients';

export default {
  getOne: (placeUri) => v1.get(`/places/${placeUri}`),
  getSlots: (body) => v1.post('/places/timeTable', body),
  makeReservation: (id, body) => v1.post(`/places/${id}/reservations`, body),
  getFavorite: (params) => v1.get(`/profile/places/liked?${queryString.stringify(params)}`),
  toggleLike: (body) => v1.put('/likes', body),
  search: (params) => v1.get(`/search/places?${queryString.stringify(params)}`),
  postFeedback: (placeUrl, body) => v1.post(`/places/${placeUrl}/reviews`, body),
  getReviews: (data) => v1.get(`/places/${data.placeUri}/reviews?page=${data.page}&pageSize=${data.pageSize}`),
  getAllCuisines: (lang) => v1.get(`/dictionaries/categories?type=cusine&lang=${lang}`),
  registerInPromo: (placeId, channelId) => v1.post(`/places/${placeId}/channels/${channelId}/join`),
  getOrganizationPlaces: (organizationId) => v1.get(`/organisations/${organizationId}/places`),
  getDeposits: (placeUri) => v1.get(`/places/${placeUri}/deposits`),
};
