import React, { memo } from 'react';
import './SelectQuantity.scss';
import PropTypes from 'prop-types';

const SelectQuantity = ({ selected, setSelected }) => {
  return (
    <div className='select-quantity__select'>
      <div className='select-quantity__select--left' onClick={() => setSelected(selected - 1)}>-</div>
      <div className='select-quantity__select--quantity'>{selected}</div>
      <div className='select-quantity__select--right' onClick={() => setSelected(selected + 1)}>+</div>
    </div>
  );
};

SelectQuantity.propTypes = {
  selected: PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default memo(SelectQuantity);
