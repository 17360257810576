import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '@components/feedback/Loading/Loading';
import Header from '@containers/Header';
import { withRouter } from 'react-router-dom';
import ApplicationError from '@pages/Error/ApplicationError';
import Animate from '@components/feedback/Animate/Animate';
import {
  ALLOW_GEOLOCATION_MODAL,
  ASK_PHONE_MODAL,
  CANCEL_RESERVATION_MODAL,
  CART_MODAL,
  DISH_DETAILS_MODAL,
  FINISH_REGISTRATION_MODAL,
  FORGOT_PASSWORD_MODAL,
  LOGIN_MODAL,
  NEW_PASSWORD_MODAL,
  PLACE_FEEDBACK_MODAL,
  PLACE_LOCATION_MODAL,
  PROMO_SUCCESS_MODAL,
  REGISTRATION_MODAL,
  RESERVATION_SUCCESS_MODAL,
  PAID_SERVICES_MODAL,
} from '@constants/modal';
import FinishRegistrationModal from '../modal/FinishRegistrationModal';
import LoginModal from '../modal/AuthModal/LoginModal';
import RegisterModal from '../modal/AuthModal/RegisterModal';
import AllowGeolocationModal from '../modal/AllowGeolocationModal';
import CancelReservationModal from '../modal/CancelReservationModal';
import AskPhoneModal from '../modal/AskPhoneModal';
import ForgotPasswordModal from '../modal/ForgotPasswordModal/ForgotPasswordModal';
import ToastrMessage from '@components/feedback/ToastrMessage/ToastrMessage';
import './PageWrapper.scss';
import PlaceLocationModal from '../modal/PlaceLocationModal';
import PlaceFeedbackModal from '../modal/PlaceFeedbackModal';
import CookieFooter from '@components/legal/CookieFooter/CookieFooter';
import LocalStorageService from '@services/LocalStorage.service';
import PromoSuccessModal from '../modal/PromoSuccessModal/PromoSuccessModal';
import {
  COOKIE_POLICY_ACCEPTED,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  PROMO_SUCCESS_PARAM,
  USER_LAST_CITY,
  USER_LAST_LANG
} from '@utils/constants';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { prepareAndRegisterInPromo } from '@actions/place';
import * as Sentry from '@sentry/react';
import { baseUrlPath, getCityFromUrl, getUserLang } from '@utils/url/urlUtils';
import { DEFAULT_CITY, DEFAULT_LANG } from '@constants/app';
import ReservationSuccessModal from '../modal/ReservationSuccessModal/ReservationSuccessModal';
import NewPasswordModal from '../modal/NewPasswordModal/NewPasswordModal';
import CartModal from '../modal/CartModal/CartModal';
import DishDetailsModal from '../modal/DishDetailsModal/DishDetailsModal';
import Footer from '../Footer/Footer';
import Div100vh from 'react-div-100vh';
import PaidServicesModal from "../../pages/PlaceInfo/components/PaidServicesModal";

const modalComponents = {
  [FINISH_REGISTRATION_MODAL]: FinishRegistrationModal,
  [LOGIN_MODAL]: LoginModal,
  [REGISTRATION_MODAL]: RegisterModal,
  [ALLOW_GEOLOCATION_MODAL]: AllowGeolocationModal,
  [CANCEL_RESERVATION_MODAL]: CancelReservationModal,
  [ASK_PHONE_MODAL]: AskPhoneModal,
  [FORGOT_PASSWORD_MODAL]: ForgotPasswordModal,
  [NEW_PASSWORD_MODAL]: NewPasswordModal,
  [PLACE_LOCATION_MODAL]: PlaceLocationModal,
  [PLACE_FEEDBACK_MODAL]: PlaceFeedbackModal,
  [PROMO_SUCCESS_MODAL]: PromoSuccessModal,
  [RESERVATION_SUCCESS_MODAL]: ReservationSuccessModal,
  [CART_MODAL]: CartModal,
  [DISH_DETAILS_MODAL]: DishDetailsModal,
  [PAID_SERVICES_MODAL]: PaidServicesModal,
};

class PageWrapper extends PureComponent {
  state = {
    error: false,
    cookiePopup: false
  };

  componentDidMount() {
    const { location, history } = this.props;
    this.props.loadInitialData();
    // this.setState({ cookiePopup: LocalStorageService.get(COOKIE_POLICY_ACCEPTED) !== 'true' });

    // Redirect user to his last known city and language from localStorage
    if (location.pathname === baseUrlPath() || location.pathname === `${baseUrlPath()}/`) {
      const currentLang = getUserLang();
      const currentCity = getCityFromUrl();
      const lastLang = LocalStorageService.get(USER_LAST_LANG);
      const lastCity = LocalStorageService.get(USER_LAST_CITY);

      if ((lastLang && lastLang !== currentLang) || (lastCity && lastCity !== currentCity)) {
        const newLang = lastLang || currentLang;
        const newCity = lastCity || currentCity;
        const newUrl = `${newLang === DEFAULT_LANG ? '' : `/${newLang}`}${newCity === DEFAULT_CITY ? '' : `/${newCity}`}`;
        history.push(newUrl);
        if (newLang !== currentLang) {
          window.location.reload();
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { location, history } = this.props;

    if (location !== prevProps.location) {
      this.setState({ error: false });
    }

    const queryParams = queryString.parse(location.search);

    if (queryParams[PROMO_SUCCESS_PARAM]) {
      prepareAndRegisterInPromo();
      delete queryParams[PROMO_SUCCESS_PARAM];
      history.push(`${location.pathname}?${queryString.stringify(queryParams)}`);
    }
  }

  componentDidCatch(renderError, errorInfo) {
    this.setState({ error: true });

    Sentry.captureException(renderError);
    const { logError } = this.props;
    logError(renderError, errorInfo);
  }

  render() {
    const { children, loading, modal } = this.props;
    const { error, cookiePopup } = this.state;

    if (error) {
      return <ApplicationError />;
    }

    // const closeCookiePopup = () => this.setState({ cookiePopup: false });

    const OpenedModal = modalComponents[modal];

    return (
      <Animate>
        <div className='root-container'>
          <ToastrMessage />
          <Header />
          {loading && <Loading />}
          <Div100vh
            style={{ minHeight: `calc(100rvh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})` }}
            className='root-content'
          >
            {!loading && children}
            {modal && <OpenedModal />}
          </Div100vh>
          <Footer />
          {/* {cookiePopup && <CookieFooter close={closeCookiePopup} />} */}
        </div>
      </Animate>
    );
  }
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  loadInitialData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  logError: PropTypes.func.isRequired,
  modal: PropTypes.string
};

PageWrapper.defaultProps = {
  modal: null,
};

const mapDispatchToProps = {
  prepareAndRegisterInPromo
};

export default connect(null, mapDispatchToProps)(withRouter(PageWrapper));
