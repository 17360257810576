import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

function Form(props) {
  const {
    children, onValidSubmit, onInvalidSubmit, onSubmit, onValid, onInvalid, formRef, className, onChange
  } = props;

  return (
    <Formsy
      onValidSubmit={onValidSubmit}
      onInvalidSubmit={onInvalidSubmit}
      onSubmit={onSubmit}
      onValid={onValid}
      onInvalid={onInvalid}
      ref={formRef}
      className={className}
      onChange={onChange}
    >
      {children}
    </Formsy>
  );
}

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.node.isRequired
  ]).isRequired,
  className: PropTypes.string,
  onValidSubmit: PropTypes.func,
  onInvalidSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,
  formRef: PropTypes.object,
  onChange: PropTypes.func
};

Form.defaultProps = {
  className: '',
  onValidSubmit: undefined,
  onInvalidSubmit: undefined,
  onSubmit: undefined,
  onValid: undefined,
  onInvalid: undefined,
  formRef: undefined,
  onChange: undefined
};

export default memo(Form);
