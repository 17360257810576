import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './Slider.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SlickSlider from 'react-slick';
import useMedia from '@utils/hooks/useMedia';
import empty from '@theme/images/empty-place-avatar-large.jpg';

function Slider({ className, place, ...restProps }) {
  const sliderHeight = useMedia(['(min-width: 1500px)'], ['450px'], '300px');

  const imageCards = useMemo(() => {
    const images = (place?.photos || []).map(img => img.url).filter(a => a);
    const imagesList = images.length ? images : [empty];
    return imagesList.map(image => (
      <div key={image}>
        <div
          style={{
            background: `url(${image}) center center no-repeat`,
            backgroundSize: 'cover',
            width: '100%',
            height: sliderHeight
          }}
        />
      </div>
    ));
  }, [place, sliderHeight]);

  if (!place) {
    return null;
  }

  return (
    <SlickSlider {...restProps} className={`slider ${className}`}>
      {imageCards}
    </SlickSlider>
  );
}

Slider.propTypes = {
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  className: PropTypes.string,
  place: PropTypes.object.isRequired
};

Slider.defaultProps = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: ''
};

export default Slider;
