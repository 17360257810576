import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './Popup.scss';
import Animate from '../Animate/Animate';

function Popup(props) {
  const { children, className, open, closing } = props;

  const animation = closing ? 'transition.slideUpOut' : 'transition.slideDownIn';
  const duration = closing ? 300 : 500;

  return (
    <>
      {open && (
        <Animate animation={animation} duration={duration} delay={0}>
          <div className={`popup ${className}`}>
            {children}
          </div>
        </Animate>
      )}
    </>
  );
}

Popup.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  closing: PropTypes.bool,
  className: PropTypes.string,
};

Popup.defaultProps = {
  className: '',
  closing: false,
};

export default memo(Popup);
