export const MODAL_OPEN_CLASS = 'modal-open';
export const SIDEBAR_OPEN_CLASS = 'sidebar-open';

export const disableBodyScroll = (identifier) => {
  document.body.classList.add(identifier);
  document.body.style.height = `${window.innerHeight.toString()}px`;
  document.body.style.overflowY = 'hidden';
  document.documentElement.style.height = `${window.innerHeight.toString()}px`;
  document.documentElement.style.overflowY = 'hidden';
};

export const enableBodyScroll = (identifier) => {
  document.body.classList.remove(identifier);

  if (!document.body.classList.contains(MODAL_OPEN_CLASS) && !document.body.classList.contains(SIDEBAR_OPEN_CLASS)) {
    document.body.style.overflowY = 'auto';
    document.body.style.height = 'auto';
    document.documentElement.style.height = 'auto';
    document.documentElement.style.overflowY = 'auto';
  }
};
