import React from 'react';
import PropTypes from 'prop-types';
import './SlidingMenu.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Swipeable } from 'react-swipeable';
import Div100vh from 'react-div-100vh';

function SlidingMenu(props) {
  const { open, onClose, className, children, submenuOpen } = props;

  const menuSwiped = (params) => {
    if (params.dir === 'Right') {
      onClose();
    }
  };

  return (
    <Div100vh
      className={`sliding-menu-overlay ${open && 'sliding-menu-overlay--visible'}`}
      role='presentation'
      tabIndex='-1'
      onClick={onClose}
    >
      <Swipeable onSwiped={menuSwiped}>
        <div
          className={`sliding-menu ${className} ${open && 'sliding-menu--visible'}`}
          role='presentation'
          tabIndex='-1'
        >
          <Div100vh className={`sliding-menu__content ${submenuOpen && 'sliding-menu__content--expanded'}`}>
            {children}
          </Div100vh>
        </div>
      </Swipeable>
    </Div100vh>
  );
}

SlidingMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.array.isRequired,
  submenuOpen: PropTypes.bool
};

SlidingMenu.defaultProps = {
  className: '',
  submenuOpen: false
};

export default SlidingMenu;
