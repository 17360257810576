import React, { memo } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import Select from '@components/inputs/Select';
import './PersonsSelector.scss';
import SvgIcon from '../../display/SvgIcon/SvgIcon';

const t = mova.ns('components.PersonsSelector');

function PersonsSelector({ className, value, fullWidth, onChange }) {
  return (
    <div className='persons-selector'>
      <SvgIcon type='guests' className='persons-selector__icon' />
      <span>{t('guests')}:</span>
      <Select
        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]}
        placeholder={t('placeholder')}
        textAlign='left'
        className={className}
        defaultValue={value}
        fullWidth={fullWidth}
        onSelect={onChange}
        fontSize={16}
      />
    </div>
  );
}

PersonsSelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

PersonsSelector.defaultProps = {
  className: '',
  value: 2,
  fullWidth: false,
};

export default memo(PersonsSelector);
