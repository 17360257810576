import { connect } from 'react-redux';
import QuickReservation from './QuickReservation';
import { getAvailableSlots } from '@actions/place';
import { addReservation, saveQuickDate, saveQuickSeats, selectNextDay } from '@actions/reservation';
import { withRouter } from 'react-router-dom';

const mapStateToProps = ({ place, reservation }) => ({
  slots: place.slots.data,
  place: place.details.data,
  slotsLoading: place.slots.isLoading,
  selectedSeats: reservation.quick.seats,
  selectedDate: reservation.quick.date,
});

const mapDispatchToProps = {
  getAvailableSlots,
  makeReservation: addReservation,
  seatsUpdated: saveQuickSeats,
  dateUpdated: saveQuickDate,
  selectNextDay,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuickReservation));
