import React, { memo } from 'react';
import './Footer.scss';
import Logo from '@components/display/Logo/Logo';
import mova from 'mova';
import { ReactComponent as PaymentsIcon } from '@theme/icons/payment/payments.svg';
import RouterLink from '../../components/navigation/RouterLink/RouterLink';
import { baseUrlPath, getUserLang } from '@utils/url/urlUtils';

const t = mova.ns('containers.Footer');

const UA_LANG_PREFIX = 'ua/';

const Footer = () => {
  const userLanguage = getUserLang();
  const addUaPrefix = userLanguage === 'ua' || userLanguage === 'ru';

  return (
    <footer className='footer'>
      <div className='footer__content content-container'>
        <div className='footer__col'>
          <Logo className='footer__logo' white />
          <span><a href='tel:+380960033434'>+380960033434</a><br />(WhatsApp, Telegram)
          </span>
          <a href='mailto:support@reservble.com'>support@reservble.com</a>
          <div>
            <PaymentsIcon style={{ maxHeight: 50, maxWidth: 260 }} />
          </div>
        </div>
        <div className='footer__col'>
          {/* <span className='text-disabled'>{t('nearMe')}</span> */}
          {/* <span className='text-disabled'>{t('openNow')}</span> */}
          {/* <span className='text-disabled'>{t('topRated')}</span> */}
          <a
            href='https://restaurant.reservble.com/privacy_policy_ua/'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('privacyPolicy')}
          </a>
          <a
            href='https://restaurant.reservble.com/cookie_policy_ua/'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('cookiePolicy')}
          </a>
          <RouterLink to={`${baseUrlPath()}/payments-policy`}>
            {t('paymentsPolicy')}
          </RouterLink>
        </div>
        <div className='footer__col'>
          <a href='https://restaurant.reservble.com/' target='_blank' rel='noopener noreferrer'>
            <span>{t('areYouRestaurant')}</span>
          </a>
          <a href='https://restaurant.reservble.com/ua/#pricing' target='_blank' rel='noopener noreferrer'>
            <span>{t('pricing')}</span>
          </a>
          <a href='https://restaurant.reservble.com/ua/onboarding-form' target='_blank' rel='noopener noreferrer'>
            <span>{t('requestDemo')}</span>
          </a>
        </div>
        <div className='footer__col'>
          <a href='https://www.facebook.com/profile.php?id=100068697577723' target='_blank' rel='noopener noreferrer'>
            Facebook
          </a>
          <a href='https://www.instagram.com/reservble/' target='_blank' rel='noopener noreferrer'>
            Instagram
          </a>
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);
