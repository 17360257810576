import React from 'react';
import PropTypes from 'prop-types';
import { VelocityComponent } from 'velocity-react';
import 'velocity-animate/velocity.ui';

const Animate = (props) => {
  const { children } = props;

  const hiddenChildren = React.cloneElement(children, {
    style: {
      ...children.style,
      visibility: 'hidden'
    }
  });

  return (
    <VelocityComponent {...props}>
      {hiddenChildren}
    </VelocityComponent>
  );
};

Animate.propTypes = {
  children: PropTypes.element.isRequired,
  animation: PropTypes.string,
  runOnMount: PropTypes.bool,
  targetQuerySelector: PropTypes.bool,
  interruptBehavior: PropTypes.string,
  visibility: PropTypes.string,
  duration: PropTypes.number,
  delay: PropTypes.number,
  easing: PropTypes.array,
};

Animate.defaultProps = {
  animation: 'transition.fadeIn',
  runOnMount: true,
  targetQuerySelector: null,
  interruptBehavior: 'stop',
  visibility: 'visible',
  duration: 300,
  delay: 50,
  easing: [0.4, 0.0, 0.2, 1]
};

export default Animate;
