import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import PageWrapper from '@containers/PageWrapper';
import Page404 from './Error/Page404';
import FinishReservation from './FinishReservation';
import PlaceInfo from './PlaceInfo';
import PrivacyPolicy from './Policy/PrivacyPolicy';
import OAuthSocialLogin from './OAuth/SocialLogin/SocialLogin';
import SearchPage from './SearchPage/SearchPage';
import Notifications from './Notifications';
import { get, isDefined } from '@utils/lo/lo';
import { connect } from 'react-redux';
import MyReservations from './MyReservations';
import ReservationHistory from './ReservationHistory';
import Favorites from './Favorites';
import ProfilePage from './Profile/ProfilePage';
import CookiePolicy from './Policy/CookiePolicy';
import Language from '@data/enums/Language.enum';
import City from '@data/enums/City.enum';
import { baseUrlPath } from '@utils/url/urlUtils';
import PlaceFeedback from './PlaceFeedback/PlaceFeedback';
import GptPlaceSearch from './GptPlaceSearch/GptPlaceSearch';
import PaymentsPolicy from './Policy/PaymentsPolicy';
import MeetingsPolicy from './Policy/MeetingsPolicy';
import Payment from './Payment/Payment';

const languageOptions = Object.values(Language).map(l => l.toLowerCase());
const cityOptions = Object.values(City).filter(c => c.enabled).map(c => c.name);
const baseRouteUrl = `/:locale(${languageOptions.join('|')})?/:city(${cityOptions.join('|')})?`;

function Router(props) {
  const { user } = props;
  const authenticated = isDefined(user);

  return (
    <PageWrapper>
      <Switch>
        <Route exact path={baseRouteUrl} component={SearchPage} />
        <Route exact path={`${baseRouteUrl}/search`} component={GptPlaceSearch} />
        <ProtectedRoute exact path={`${baseRouteUrl}/favorites`} authenticated={authenticated} component={Favorites} />
        <Route exact path={`${baseRouteUrl}/places/:placeUri`} component={PlaceInfo} />
        <Route exact path={`${baseRouteUrl}/places/:placeUri/feedback/:token`} component={PlaceFeedback} />
        <Route path={`${baseRouteUrl}/oauth/token`} component={OAuthSocialLogin} />
        <Route exact path={`${baseRouteUrl}/finish/:placeUri/:reservationId`} component={FinishReservation} />
        <ProtectedRoute exact path={`${baseRouteUrl}/reservations`} authenticated={authenticated} component={MyReservations} />
        <ProtectedRoute exact path={`${baseRouteUrl}/history`} authenticated={authenticated} component={ReservationHistory} />
        <ProtectedRoute path={`${baseRouteUrl}/notifications`} authenticated={authenticated} component={Notifications} />
        <ProtectedRoute path={`${baseRouteUrl}/profile`} authenticated={authenticated} component={ProfilePage} />
        <Route exact path={`${baseRouteUrl}/privacy-policy`} component={PrivacyPolicy} />
        <Route exact path={`${baseRouteUrl}/cookie-policy`} component={CookiePolicy} />
        <Route exact path={`${baseRouteUrl}/payments-policy`} component={PaymentsPolicy} />
        <Route exact path={`${baseRouteUrl}/meetings-policy`} component={MeetingsPolicy} />
        <Route exact path={`${baseRouteUrl}/p/:billingHash`} component={Payment} />
        <Route path='*' component={Page404} />
      </Switch>
    </PageWrapper>
  );
}

const ProtectedRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (authenticated) {
        return <Component {...props} />;
      }

      return <Redirect to={baseUrlPath()} />;
    }}
  />
);

Router.propTypes = {
  user: PropTypes.object
};

Router.defaultProps = {
  user: undefined
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]).isRequired,
  authenticated: PropTypes.bool
};

ProtectedRoute.defaultProps = {
  authenticated: false
};

const mapStateToProps = ({ user }) => ({
  user: get(user, 'details.data.user')
});

export default connect(mapStateToProps)(Router);
