import React, { memo } from 'react';
import './RadioButton.scss';
import PropTypes from 'prop-types';
import { get } from '@utils/lo/lo';

function RadioButton(props) {
  const { children, disabled, name, setValue, required, checked, value } = props;

  const radioClicked = (e) => {
    setValue(get(e, 'currentTarget.value'));
  };

  return (
    <label className='radiobutton-container'>
      <input
        type='radio'
        className='radiobutton'
        onChange={radioClicked}
        disabled={disabled}
        defaultChecked={checked}
        name={name}
        value={value}
        required={required}
      />
      <div className='area'>
        <div className='checkmark' />
      </div>
      <div className='text'>{children}</div>
    </label>
  );
}

RadioButton.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  required: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.string.isRequired
};

RadioButton.defaultProps = {
  disabled: false,
  name: undefined,
  required: false,
  checked: false
};

export default memo(RadioButton);
