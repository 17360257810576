import variables from '../../view/theme/styles/index.scss';

export default {
  TEXT_BLACK: variables.textBlack,
  TEXT_DEFAULT: variables.textDefault,
  TEXT_SECONDARY: variables.textSecondary,
  TEXT_DISABLED: variables.textDisabled,
  TEXT_GREY: variables.textGrey,
  TEXT_LIGHT_GREY: variables.textLightGrey,
  COLOR_PRIMARY_VERY_DARK: variables.colorPrimaryVeryDark,
  COLOR_PRIMARY_DARK: variables.colorPrimaryDark,
  COLOR_PRIMARY_STRONG: variables.colorPrimaryStrong,
  COLOR_PRIMARY: variables.colorPrimary,
  COLOR_PRIMARY_MIDDLE: variables.colorPrimaryMiddle,
  COLOR_PRIMARY_LIGHT: variables.colorPrimaryLight,
  COLOR_DANGER: variables.colorDanger,
  COLOR_DANGER_PRESSED: variables.colorDangerPressed,
  COLOR_LINK_MAIN: variables.colorLinkMain,
  COLOR_LINK_LIGHT: variables.colorLinkLight,
  COLOR_MENU_HOVER: variables.colorMenuHover,
  COLOR_YELLOW: variables.colorYellow
};
