import React, { memo } from 'react';
import mova from 'mova';
import './CookieFooter.scss';
import Button from '@components/inputs/Button/Button';
import LocalStorageService from '@services/LocalStorage.service';
import { COOKIE_POLICY_ACCEPTED } from '@utils/constants';
import PropTypes from 'prop-types';
import TextLink from '@components/navigation/TextLink/TextLink';
import { baseUrlPath } from '@utils/url/urlUtils';

const t = mova.ns('components.CookieFooter');

function CookieFooter(props) {
  const { close } = props;

  const policyAccepted = () => {
    LocalStorageService.set(COOKIE_POLICY_ACCEPTED, 'true');
    close();
  };

  return (
    <div className='cookie-footer'>
      <span className='cookie-footer__text'>
        <span>{t('message')}</span>&nbsp;
        <TextLink to={`${baseUrlPath()}/cookie-policy`} size='medium' className='cookie-footer__link' newPage>
          {t('learnMore')}
        </TextLink>
      </span>
      <Button size='medium' onClick={policyAccepted}>{t('okText')}</Button>
    </div>
  );
}

CookieFooter.propTypes = {
  close: PropTypes.func.isRequired
};

CookieFooter.defaultProps = {};

export default memo(CookieFooter);
