export default {
  name: 'ua',
  base: {
    cancel: 'Скасувати',
    signIn: 'Увійти',
    signUp: 'Реєстрація',
    signOut: 'Вихід',
    submitReservationForm: 'Заброньовано для <=seats>',
    submitFilterForm: 'Пошук...',
    save: 'Зберегти зміни',
    anycafe: 'RESERVBLE',
    book: 'Забронювати',
    selectTime: 'Виберіть час',
    or: 'Або',
    createAccount: 'Створити акаунт',
    back: 'Повернутися',
    ok: 'Ок',
    total: 'Загальна сума',
  },
  cities: {
    odessa: 'Одеса',
    kyiv: 'Київ',
    ternopil: 'Тернопіль',
    dnipro: 'Дніпро',
    lviv: 'Львів',
    kharkiv: 'Харків',
    uzhhorod: 'Ужгород',
    bilatserkv: 'Біла Церква',
  },
  currencies: {
    uah: 'грн'
  },
  types: {
    likes: {
      add: 'Ви додали <=place> до улюблених закладів',
      delete: 'Ви видалили <=place> з улюблених закладів',
      addDish: 'Страва додана до списку улюблених',
      deleteDish: 'Страва видалена зі списку улюблених',
    },
    user: {
      phone: 'Телефон',
      phoneLower: 'телефон',
      email: 'Е-мейл',
      emailLower: 'е-мейл',
      password: 'Пароль',
      newPassword: 'Новий пароль',
      repeatPassword: 'Повторіть пароль',
      name: 'Ім\'я',
      surname: 'Прізвище',
      rememberMe: 'Запам\'ятати мене',
    },
    reservation: {
      '@extends': 'types.user',
      button: 'Забронювати',
      comment: 'У Вас є побажання?',
      date: 'Дата/Час',
      status: {
        REQUESTED: 'В процесі',
        ACCEPTED: 'Підтверджено',
        FINISHED: 'Завершено',
        CANCELED: 'Скасовано',
        SKIPPED: 'Пропущено',
        REJECTED: 'Відмовлено'
      }
    },
    filters: {
      city: 'Виберіть місто',
    },
    feedback: {
      thankYou: 'Дякуємо за Ваш відгук',
    },
    join: {
      thankYou: 'Дякуємо за Ваш запит. Наш адміністратор зв\'яжеться з Вами найближчим часом',
    }
  },

  errors: {
    '@root': 'Невідома помилка',
    '@any': '<.>',
    error: 'Помилка',
    server: 'На сервері виникла невідома помилка',
    validation: {
      '@root': 'Неправильний формат',
      '@any': '<.>',
      required: 'Поле обов\'язкове для заповнення',
      email: 'Введіть коректний <types.user.emailLower>',
      phone: 'Введіть коректний <types.user.phoneLower>',
      minLength: {
        1: 'Мінімум <=symbols> символ',
        2: 'Мінімум <=symbols> символа',
        5: 'Мінімум <=symbols> символів',
      },
      maxLength: {
        1: 'Максимум <=symbols> символ',
        2: 'Максимум <=symbols> символа',
        5: 'Максимум <=symbols> символів',
      },
      minNumber: 'Мінімальне значення <=min>',
      maxNumber: 'Максимальне значення <=max>',
      repeatPassword: 'Паролі не співпадають',
    },
    fields: {
      email: '<types.user.emailLower>ом',
      phone: 'телефоном',
      notUniq: 'Користувач із таким <=field> існує в системі'
    },
    common: {
      unauthorized: 'Вказана дія може бути виконана тільки авторизованим користувачем',
      wrongAuth: 'Вказаний неправильний телефон або пароль. Перевірте коректність введених даних',
      notFound: 'Об\'єкт пошуку не знайдено',
      oldPasswordIsInvalid: 'Поточний пароль вказаний невірно. Спробуйте ще раз',
      justVisitorCanLeaveAComment: 'Відгук можна залишити тільки після завершення підтвержденого резерву',
      userWithSpecifiedEmailNotFound: 'Користувач із вказаною адресою не знайдений',
    },
    join: 'На сервері виникла невідома помилка. Надішліть, будь ласка, повідомлення нашому адміністратору на телефон, вказаний внизу сторінки'
  },

  containers: {
    SearchBar: {
      placeholder: 'Назва, кухня, тип, адреса',
      search: 'Пошук',
      useAi: 'Використати магію AI',
    },
    Header: {
      signIn: '<base.signIn>',
      signUp: '<base.signUp>',
      signOut: '<base.signOut>',
      placeOwner: 'Рестораторам',
      join: 'Підключити заклад',
    },

    SortResults: {
      sortBy: 'Сортувати по:',
      sort: {
        alphabet: 'Алфавіту',
        rating: 'Рейтингу',
        nearest: 'Найближчі',
        expensive: 'Найбільш дорогі',
        cheap: 'Найбільш дешеві',
        free: 'Найбільше вільних місць',
      }
    },

    UserFeedbacks: {
      empty: 'У Вас поки що немає відгуків',
      moreReviews: 'Показати ще <=pageSize> відгуків'
    },

    MailingPreferences: {
      notifications: 'Надсилати мені на пошту повідомлення',
      discounts: 'Надсилати мені на пошту новини про знижки',
      selections: 'Надсилати мені на пошту добірки ресторанів',
      save: '<base.save>'
    },

    UserPreferences: {
      city: 'Основне місто',
      cityPlaceholder: 'Ваше основне місто проживання',
      allergy: 'Непереносності / Алергії',
      allergyPlaceholder: 'Вкажіть будь-які непереносності або алергії',
      favoriteCuisine: 'Улюблені кухні',
      favoriteCuisinePlaceholder: 'Розкажіть які кухні Ви любите',
      favoriteDishes: 'Улюблені страви',
      favoriteDishesPlaceholder: 'Розкажіть про Ваші улюблені страви',
      unliked: 'Інгредієнти / страви які Вам не подобаються',
      unlikedPlaceholder: 'Розкажіть що Вам не подобається',
      save: '<base.save>'
    },

    UserProfile: {
      photo: {
        delete: 'Видалити фото',
        new: 'Нове фото',
      },
      info: 'Інфо',
      stats: {
        cities: {
          1: 'місто',
          2: 'міста',
          5: 'міст'
        },
        visits: {
          1: 'відвідування',
          2: 'відвідування',
          5: 'відвідувань'
        },
        rates: {
          1: 'оцінка',
          2: 'оцінки',
          5: 'оцінок'
        },
        feedbacks: {
          1: 'відгук',
          2: 'відгука',
          5: 'відгуків'
        }
      },
      achievement: {
        rates: 'оцінок на <base.anycafe>',
        bookings: 'бронювань з <base.anycafe>'
      },
      data: {
        name: '<types.user.name>',
        namePlaceholder: 'Введіть Ваше ім\'я',
        surname: '<types.user.surname>',
        surnamePlaceholder: 'Введіть Ваше прізвище',
        phone: '<types.user.phone>',
        email: '<types.user.email>',
        emailPlaceholder: 'Введіть Ваш <types.user.emailLower>',
        password: '<types.user.password>',
        passwordPlaceholder: 'Введіть Ваш пароль',
        confirmPassword: 'Підтвердження пароля',
        confirmPasswordPlaceholder: 'Підтвердіть Ваш пароль',
        newPassword: '<types.user.newPassword>',
        newPasswordPlaceholder: '<types.user.newPassword>',
      },
      social: {
        link: 'Підключити соцмережі',
        linked: 'підключений',
        notLinked: 'не підключений',
        linkButton: 'Підключити',
        unlinkButton: 'Відключити'
      },
      save: '<base.save>'
    },

    QuickReservation: {
      book: '<base.book>',
      selectTime: '<base.selectTime>',
      noOptions: 'На жаль, на обраний час вільних місць немає',
      nextDay: 'Перевірити наступний день'
    },

    TopUserMenu: {
      notifications: 'Повідомлення',
      currentReserve: 'Поточні бронювання',
      history: 'Історія',
      favorites: 'Вибране',
      profile: 'Профіль',
      logout: '<base.signOut>'
    },

    FinishRegistrationModal: {
      lastStep: 'Залишився один крок для завершення реєстрації :)',
      password: '<types.user.password>',
      repeatPassword: '<types.user.repeatPassword>',
      rememberMe: '<types.user.rememberMe>',
      createAccount: '<base.createAccount>'
    },

    AskPhoneModal: {
      lastStep: 'Залишився один крок для завершення Вашого резерву :)',
      text: 'Вкажіть Ваш телефон, щоб адміністратор мав можливість зателефонувати Вам, якщо виникне така необхідність',
      continue: 'Продовжити'
    },

    LoginRegisterModal: {
      signInTitle: 'Будь ласка, увійдіть в <base.anycafe>',
      signUpTitle: 'Вітаємо в <base.anycafe>',
      or: '<base.or>',
      name: '<types.user.name>',
      surname: '<types.user.surname>',
      email: '<types.user.email>',
      password: '<types.user.password>',
      repeatPassword: '<types.user.repeatPassword>',
      rememberMe: '<types.user.rememberMe>',
      forgotPassword: 'Забули пароль?',
      signIn: '<base.signIn>',
      signUp: '<base.createAccount>',
      newUserQuestion: 'Ви новенький?',
      haveAccountQuestion: 'Вже зареєстровані?',
      signUpLink: 'Реєстрація',
      signInLink: 'Логін'
    },

    PromoSuccessModal: {
      congrats: 'Вітаємо!',
      defaultMessage: 'Ви приймаєте участь у промо-акції'
    },

    CancelReservationModal: {
      title: 'Скасувати бронювання',
      why: 'Будь ласка, вкажіть, чому Ви хочете скасувати бронювання',
      changeOfPlans: 'Змінилися плани',
      mistake: 'Бронювання було створено помилково',
      late: 'Не встигаю на зазначений час',
      changeTime: 'Хочу обрати інший час',
      other: 'Інше',
      cancel: 'Скасувати'
    },

    AllowGeolocationModal: {
      title: 'Доступ до геолокації',
      text: 'Щоб мати можливість показувати Вам найбільш релевантні результати пошуку і відстань до ресторану, надайте, будь ласка, додатку доступ до Вашої геолокації',
      ok: '<base.ok>',
    },

    FeedbackForm: {
      whatAbout: 'Як Вам?',
      comment: 'Ваш відгук:',
      commentPlaceholder: 'Як Вам заклад? (не обов\'язково)',
      cancel: '<base.cancel>',
      send: 'Відправити',
    },

    ForgotPasswordModal: {
      title: 'Відновити пароль',
      text: 'Введіть Ваш <types.user.emailLower>',
      textSms: 'Введіть Ваш <types.user.phone>',
      email: '<types.user.email>',
      send: 'Відправити',
      sendAgain: 'Відправити знову',
      back: '<base.back>',
      or: '<base.or>',
      codeReceived: 'Я отримав SMS з кодом',
    },

    NewPasswordModal: {
      title: 'Зміна пароля',
      text: 'Введіть код з SMS',
      back: '<base.back>',
      code: 'Код з SMS',
      send: 'Відправити',
      newPassword: '<types.user.newPassword>',
      repeatPassword: '<types.user.repeatPassword>',
      successPasswordChange: 'Пароль було успішно змінено',
    },

    ChangeCityPopup: {
      yourCity: 'Ваше місто',
      yes: 'Так',
      no: 'Ні, залишити',
      cities: {
        '@extends': 'cities'
      },
    },

    ReservationSuccessModal: {
      title: 'Вітаємо!',
      p1: 'Місце заброньовано! Після підтвердження адміністратором ви отримаєте SMS повідомлення.',
      p1noSms: 'Друже, місце заброньовано успішно! Якщо у нас виникнуть якісь питання - то ми тобі зателефонуємо.',
      p2: 'Зареєструйтесь та отримайте більше переваг від Reservble.',
      register: 'Зареєструватися',
    },

    CartModal: {
      title: 'Ваше замовлення',
      total: '<base.total>',
      uah: '<currencies.uah>',
      empty: 'Ви поки не обрали жодної страви',
      clear: 'Очистити замовлення',
    },

    Footer: {
      privacyPolicy: '<pages.Policy.privacy>',
      cookiePolicy: '<pages.Policy.cookies>',
      paymentsPolicy: '<pages.Policy.payments>',
      nearMe: 'Ресторани біля мене',
      openNow: 'Ресторани відкриті зараз',
      topRated: 'Популярні ресторани',
      areYouRestaurant: 'Ви власник ресторану?',
      pricing: 'Тарифи та ціни',
      requestDemo: 'Запит на демо',
    },
  },

  components: {
    Feedback: {
      review: 'Ваш відгук',
      alreadyLeft: 'Ви вже залишили відгук',
      empty: 'Про цей заклад ще немає відгуків'
    },
    Success: {
      successMsg: 'Успішно!',
    },
    InfoTags: {
      more: 'Ще'
    },
    TimeTags: {
      more: 'Ще'
    },
    CookieFooter: {
      message: 'Ми використовуємо файли cookie для забезпечення найкращої роботи нашого сервісу. Використовуючи цей сайт, Ви погоджуєтесь на використання файлів cookie.',
      learnMore: 'Дізнатися більше',
      okText: 'Ок'
    },
    ViewSelector: {
      map: 'Мапа',
      tiles: 'Плитка',
    },
    Distance: {
      m: 'м',
      km: 'км'
    },
    PlaceCard: {
      cancelReservation: 'Скасувати бронювання',
      reservationsToday: {
        1: 'людина забронювала сьогодні',
        2: 'людини забронювали сьогодні',
        5: 'людей забронювали сьогодні',
      },
    },
    QueryResult: {
      results: {
        1: 'заклад знайдено',
        2: 'заклади знайдено',
        5: 'закладів знайдено',
      },
    },
    SocialLoginButton: {
      text: 'Увійти через <=provider>',
      google: 'Google',
      facebook: 'Facebook'
    },
    PlaceContacts: {
      address: 'Адреса:',
      contacts: 'Контакти:',
      webSite: 'Веб-сайт:'
    },
    DetailedRating: {
      heading: 'Рейтинг закладу',
      kitchen: 'Кухня',
      interior: 'Інтер\'єр',
      service: 'Сервіс',
      atmosphere: 'Атмосфера',
      noFeedbacks: '<components.Feedback.empty>',
      leaveFeedback: '<pages.PlaceInfo.leaveFeedback>',
      beTheFirst: '. Будьте першим!'
    },
    PersonsSelector: {
      placeholder: 'Чоловік',
      suffix: 'чол.',
      guests: 'Гості',
    },
    ReservationDetails: {
      persons: 'чол.'
    },
    DishCard: {
      uah: '<currencies.uah>',
      like: 'Мені подобається',
    },
    ViewOrder: {
      view: 'Переглянути',
      uah: '<currencies.uah>'
    },
    Calendar: {
      date: 'Дата',
    },
    TimeSelector: {
      time: 'Час',
    },
  },

  pages: {
    Search: {
      noResults: 'По вашому запиту не знайдено ресторанів',
      topLabel: 'Один пошук, безліч можливостей',
    },

    Favorites: {
      empty: 'У Вас поки що немає улюблених місць',
      favorites: 'Ви додали',
      places: {
        1: 'заклад до списку улюблених',
        2: 'заклади до списку улюблених',
        5: 'закладів до списку улюблених',
      }
    },

    Notifications: {
      youHave: 'У Вас',
      notifications: {
        1: 'нове повідомлення',
        2: 'нових повідомлення',
        5: 'нових повідомлень'
      },
      new: 'Нові',
      read: 'Прочитані',
      reservation: {
        reserv: 'Ваше бронювання у',
        atDate: 'на',
        for: 'для',
        persons: {
          1: 'персони',
          2: 'персон',
          5: 'персон',
        },
        confirmed: 'було підтверджено',
        rejected: 'було відхилено',
        awaiting: 'очікує на підтвердження',
        cancelled: 'було скасовано',
        finished: 'було успішно завершено. Чекаємо на Вас знову!',
        skipped: 'було відмічено як пропущене',
        message: '. Повідомлення від адміністратора: "<=message>"',
        didYouLike: 'Сподобався ресторан?',
        leaveFeedback: 'Залиште відгук!'
      }
    },

    DemoStub: {
      message: '<base.anycafe> бета-тестування',
      open: 'Офіційне відкриття',
      openDate: '1 лютого 2020 року',
      countdown: {
        days: 'днів',
        hours: 'годин',
        minutes: 'хвилин',
        seconds: 'секунд'
      }
    },

    Error: {
      error404: 'Вибачте, сторінка, яку Ви шукали, не знайдена',
      error500: {
        message: 'Ви зламали Інтернет!',
        details: 'Жартуємо, схоже у нас невелика проблема на сервері, повторіть Ваш запит через кілька хвилин'
      },
      entity404: 'Вибачте, <=entity> не знайдений',
      reservationNotDraft: 'Дане бронювання вже було оформлено',
      goToReservations: 'Перейти на сторінку "<containers.TopUserMenu.currentReserve>"',
      goHome: 'Повернутися на головну сторінку',
    },

    Profile: {
      profile: 'Мої дані',
      preferences: 'Уподобання',
      feedback: 'Мої відгуки',
      mailing: 'Розсилки',
      update: 'Дані успішно оновлені'
    },

    FinishReservation: {
      almost: 'Майже закінчили!',
      timer: {
        prefix1: 'Ми тримаємо для Вас',
        prefix2: 'місце протягом',
        suffix: 'хвилин'
      },
      or: '<base.or>',
      name: '<types.user.name>',
      surname: '<types.user.surname>',
      email: '<types.user.email>',
      selectHall: 'Ви можете обрати зал:',
      depositOptions: 'Додаткові опції:',
      depositsRequired: 'Необхідно вибрати хоча б одну додаткову опцію для створення бронювання',
      uah: '<currencies.uah>',
      total: '<base.total>',
      comment: 'Ваш коментар',
      subscribe: 'Підписатися на розсилку',
      notifications: 'Отримувати нагадування',
      buttonName: '<types.reservation.button>',
      reservation: 'резерв',
      back: 'Повернутися до закладу',
      submit: '<types.reservation.button>',
    },

    Payment: {
      reservation: 'резерв',
      title: 'Оплата депозиту',
      uah: '<currencies.uah>',
      success: 'Оплата успішна!',
      back: 'Повернутися до закладу',
      linkExpired: 'Ваше посилання на оплату прострочене.',
      tryAgain: 'Спробуйте забронювати знову',
      tryAgainButton: 'Забронювати знову',
      orderNumber: 'Номер замовлення',
      paymentTime: 'Час оплати',
      amount: 'Сума',
      waitFormLoad: 'Дочекайтесь завантаження форми оплати не перезавантажуючи сторінку',
      payWithPortmone: 'Оплатити через Portmone.com',
    },

    PlaceInfo: {
      cuisine: 'Кухня',
      additional: 'Додатково',
      payment: 'Можливості оплати',
      info: 'Інформація',
      menu: 'Меню',
      likedCategory: 'Улюблене',
      description: 'Опис',
      comments: 'Коментарі',
      status: {
        open: 'Відкрито',
        closed: 'Зачинено'
      },
      closing: 'Зачиняється:',
      opening: 'Відкриття:',
      averageBill: 'Середній чек',
      place: 'ресторан',
      leaveFeedback: 'Залишити відгук',
      moreReviews: 'Показати ще <=pageSize> відгуків',
      paidServices: 'Додаткові послуги',
      viewPaidServices: 'Переглянути послуги',
    },

    PlaceFeedback: {
      leaveFeedback: 'Залиште відгук для',
      place: 'ресторан',
      user: 'користувач',
    },

    MyReservations: {
      summary: {
        booked: {
          1: 'Заброньований',
          2: 'Заброньовано',
          5: 'Заброньовано',
        },
        tables: {
          1: 'місце',
          2: 'місця',
          5: 'місць',
        },
        pending: {
          1: ', очікує підтвердження',
          2: ', очікують підтвердження',
          5: ', очікують підтвердження'
        }
      },
      inProcess: 'Очікує підтвердження',
      confirmed: 'Підтверджено',
      empty: 'Активних бронювань не знайдено'
    },

    ReservationHistory: {
      summary: {
        visits: 'Ви відвідали',
        reservations: {
          1: 'заклад',
          2: 'заклади',
          5: 'закладів',
        }
      },
      empty: 'Попередніх бронювань не знайдено'
    },

    Policy: {
      privacy: 'Політика конфіденційності',
      privacyGeneric: 'Політикою конфіденційності',
      cookies: 'Політика використання файлів кукі',
      cookiesGeneric: 'Політикою використання файлів кукі',
      payments: 'Політика проведення оплат',
      meetings: 'Договір оферта зустрічі',
    },

    GptPlaceSearch: {
      back: 'На головну',
      title: 'Використовуємо магію AI',
      firstMessage: 'Привіт, я чат Reservble AI. Напишіть страву, кухню, напій або опишіть враження, які ви хочете отримати.',
      placeholderMessages: 'Спитай мене: "Де поїсти стейк?"',
    },
  },

  actions: {
    user: {
      forgotPasswordSent: 'Посилання для відновлення пароля успішно відправлено. Перевірте Ваш <types.user.emailLower>',
      forgotSmsSent: 'Ми відправили Вам SMS повідомлення з кодом',
      passwordChanged: 'Пароль було успішно змінено',
    }
  },

  legal: {
    heading: '<pages.Policy.privacy>',
    confirmation: {
      '@extends': 'pages.Policy',
      confirm1: 'Натискаючи на кнопку <=buttonName>, Ви погоджуєтесь із',
      confirm2: ', а також нашою',
      confirm3: ', включаючи',
    }
  }
};
